<template>
  <div
    class="menu_wrapper_container box-shadow trading-container"
    v-loading="loading"
  >
    <el-card class="setting_wrapper" style="margin-top: 8px;">
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <div>
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="left">
              <h3>Config:</h3>
            </el-col>
          </el-row>

          <el-row class="trading-client">
            <!-- <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.execute_pending_orders_at_ltp"
                label=""
                prop="execute_pending_orders_at_ltp"
                :label-width="formLabelWidth"
              >
                <el-checkbox v-model="formData.execute_pending_orders_at_ltp"
                  >Execute Pending Orders At Ltp</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.first_contract_only"
                label=""
                prop="first_contract_only"
                :label-width="formLabelWidth"
              >
                <el-checkbox v-model="formData.first_contract_only"
                  >First Contract Only</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.traded_by_admin_message"
                label=""
                prop="traded_by_admin_message"
                :label-width="formLabelWidth"
              >
                <el-checkbox v-model="formData.traded_by_admin_message"
                  >Traded By Admin Message</el-checkbox
                >
              </el-form-item>
            </el-col> -->
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.allow_client_multi_login"
                label=""
                prop="allow_client_multi_login"
                :label-width="formLabelWidth"
              >
                <el-checkbox v-model="formData.allow_client_multi_login"
                  >Allow client multi login</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.allow_broker_multi_login"
                label=""
                prop="allow_broker_multi_login"
                :label-width="formLabelWidth"
              >
                <el-checkbox v-model="formData.allow_broker_multi_login"
                  >Allow broker multi login</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item
                :error="formErrors.sound_play_in_trade"
                label=""
                prop="sound_play_in_trade"
                :label-width="formLabelWidth"
              >
                <el-checkbox v-model="formData.sound_play_in_trade"
                  >Sound play in trade</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="right">
            <el-button type="info" @click="sendChildFormData()">Save</el-button>
            <!-- {{ userInfo.id }} -->
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>




<script>
import { fetch, store } from "@/api/settings";

export default {
  name: "AddEdit",
  components: {
  },
  props: {
    // dialogVisible: {
    //   type: Boolean,
    //   default: false,
    // },
    // dialogType: {
    //   type: String,
    //   default: "",
    // },
    // formData: {
    //   type: Object,
    //   default: function () {
    //     return {};
    //   },
    // },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formData: {},
      formRules: {},
      formLabelWidth: "120px",
    };
  },
  computed: {},
  created() {
    this.fetchData();
  },
  methods: {
    mcxStartTradingAt(value) {
      this.formData.mcx_start_trading_at = value;
    },
    mcxStopTradingAt(value) {
      this.formData.mcx_stop_trading_at = value;
    },
    optStartTradingAt(value) {
      this.formData.opt_start_trading_at = value;
    },
    optStopTradingAt(value) {
      this.formData.opt_stop_trading_at = value;
    },
    equityFuturesStartTradingAt(value) {
      this.formData.equity_futures_start_trading_at = value;
    },
    equityFuturesStopTradingAt(value) {
      this.formData.equity_futures_stop_trading_at = value;
    },
    ///////////////////
    //// EDIT
    ///////////////////
    fetchData() {
      fetch().then((response) => {
        console.log(response);
        this.formData = response.data.data;
        this.formData.execute_pending_orders_at_ltp =
          this.formData.execute_pending_orders_at_ltp == "true" ? true : false;
        this.formData.first_contract_only =
          this.formData.first_contract_only == "true" ? true : false;
        this.formData.traded_by_admin_message =
          this.formData.traded_by_admin_message == "true" ? true : false;
          this.formData.sound_play_in_trade == true ? true : false;
          this.formData.allow_broker_multi_login == true ? true : false;
          this.formData.allow_client_multi_login == true ? true : false;
        console.log(this.formData);
        // this.loading = false;
      });
    },
    ///////////////////
    //// UPDATE
    ///////////////////
    sendChildFormData() {
      this.loading = true;
      // if (
      //   this.formData.transaction_password != this.userInfo.transaction_password
      // ) {
      //   this.flashError("Please Enter Correct Transaction Password");
      //   this.formData.transaction_password = null;
      // } else {
      //   this.formData.transaction_password;
      // }
      //   console.log(this.formData)
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          //   this.$emit("getChildFormData", this.formData);

          store(this.formData)
            .then((response) => {
              if (response.data.success === true) {
                this.flashSuccess(response.data.message);
                this.loading = false;
                this.$router.push("/admin/settings");
              } else {
                this.formErrors = this.validationErrors(response.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });

          console.log(this.formData);
          this.loading = false;
        }
      });
    },
  },
};
</script>

