import request from '@/utils/request'

/*
@keyword : staff-api-hosts
*/
// export function getSettings(query) {
//   return request({
//     url: 'settings',
//     method: 'get',
//     params: query
//   })
// }

export function fetch(query) {
  return request({
    url: 'settings',
    method: 'get',
    params: query
  })
}
export function fetchMasterSettings(query) {
  return request({
    url: 'master/settings',
    method: 'get',
    params: query
  })
}



export function store(data) {
  return request({
    url: '/settings/update',
    method: 'post',
    data
  })
}
export function CloseTrade(data) {
  return request({
    url: 'trades/close-all',
    method: 'post',
    data
  })
}

export function blockScript(data) {
  return request({
    url: '/settings/blockscript',
    method: 'post',
    data
  })
}

export function carryFroward(query) {
  return request({
    url: 'trades/carry-forward',
    method: 'get',
    params: query
  })
}

export function generateBill(query) {
  return request({
    url: 'trades/generate-bill',
    method: 'get',
    params: query
  })
}

export function getClient(query) {
  return request({
    url: 'clients/all',
    method: 'get',
    params: query
  })
}

